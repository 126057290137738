export default {
  // api路径
  // BASE_URL: process.env.NODE_ENV === 'development'? 'http://homebackend.gzswater.jxlcit.com/swhome/api' : '/swhome/api',
  BASE_URL: process.env.NODE_ENV === 'development'? 'http://111.230.228.15:8083/api' : '/swhome/api',
  // 网上营业厅URL, 用户留言
  WT_URL: process.env.NODE_ENV === 'development'? 'https://service.jcy.gxqzlsyxgs.com/swamp' : '/swamp',
  // process.env.NODE_ENV === 'development'
  // 图片等资源路径
  // CDN_URL: 'https://gzwater.gzlcnet.cn/swamp',
  // CDN_URL: process.env.NODE_ENV === 'development'? 'http://homebackend.gzswater.jxlcit.com/swhome/common/getf?objName=' : '/swhome/common/getf?objName=',
  CDN_URL: process.env.NODE_ENV === 'development'? 'http://111.230.228.15:8083/common/getf?objName=' : '/swhome/common/getf?objName=',
}